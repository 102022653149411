import * as React from "react";
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import LeadButton from "./LeadButton";
import icon from "../../images/logo.png";

const Header = (props) => {
    return (
        <Box
            sx={{
                background: props.coverImage?.node?.fluid?.src
                    ? "linear-gradient( rgba(0, 0, 0, 0), rgba(0, 0, 0, .6) ), url(" +
                    props.coverImage?.node?.fluid?.src +
                    ")"
                    : "block",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundPosition: "center top",
                display: {md: "flex"},
                justifyContent: "center",
                backgroundColor: "background.main",
                color: "header.text",
            }}
        >
            <Container
                sx={{
                    py: 4,
                    maxHeight: "700px",
                }}
            >
                <Stack direction={"row"} spacing={1} alignItems="center">
                    {props.brand.logo && (
                        <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            p={1}
                            borderRadius={100}
                        >
                            <img src={icon} alt={props.brand.name} height={"40px"}/>
                        </Box>
                    )}
                    <Typography variant="h5">{props.brand.name}</Typography>
                </Stack>
                <Stack
                    sx={{
                        pt: {xs: 12, sm: 10},
                        pb: 4,
                        px: {lg: 8},
                        textAlign: "center",
                    }}
                    spacing={2}
                >
                    <Typography variant="h1">{props.brand.briefDescription}</Typography>

                    <Typography
                        variant="h4"
                        fontWeight={"medium"}
                        color="header.text"
                        sx={{px: {md: 7, lg: 8}}}
                    >
                        {props.brand.longDescription}
                    </Typography>
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            pt: 4,
                        }}
                    >
                        <LeadButton
                            website={props.website}
                            color={"buttons"}
                        />
                    </Box>
                </Stack>
            </Container>
        </Box>
    );
};

export default Header;